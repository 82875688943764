.custom-tooltip {

  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;

  span {
    display: block;
    padding: 12px;
  }

  .tooltip-label {
    background-color: #3498db;
    font-size: 12px;
  }

  .tooltip-value {
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
}