.custom-input-group{
    display: flex;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #00000008;
    overflow: hidden;
}
.custom-input-all{
    width: 13rem;
    min-width: 250px;
    padding: 8px 12px;
    min-height: 40px;
    color: #495057;
    font-size: 16px;
    border: none;
    background-clip: padding-box;
    background-image: url("assets/images/svg/search.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 12px;
}
.custom-input-all:focus-visible {
    outline: none!important;
}
.input-label{
    font-size: 15px;
    color: #313649;
    letter-spacing: -0.02em;
}
