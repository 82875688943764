html,
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important;
}

.app-container {
  max-width: 2000px;
  margin: 0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-remove {
  display: inline-block;
  position: relative;

  height: 40px;
  border: none;
  outline: none;

  padding: 8px 16px;
  gap: 8px;
  background: #db5962;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
  margin-left: 12px;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.downloadExel-icon {
  width: 40px;
  height: 40px;

  background: #fcfcfc;
  border-radius: 8px;
  padding: 8px;
  margin-left: 12px;
  cursor: pointer;
  svg {
    path {
      fill: #00b2f5;
    }
  }
}
