@import url("https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@300;400;500;600;700;800&family=Montserrat:wght@400;600&family=Roboto:wght@300;400;500;700&display=swap");

@import "./config";
@import "./common";

.group-submit__button {
  margin-left: auto;
  text-align: right;

  button {
    width: 224px;
    height: 48px;
  }
}
